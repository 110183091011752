import React from 'react'
import PropTypes from 'prop-types'
import { ChevronIcon } from '@boltui/icons'
import { megaMenuLevelOneUrlBuilder, megaMenuUrlBuilder } from '../../helpers/analyticsUrlBuilder'
import { MegaMenuCategory } from '../../components'
import { isDesktop, isMobile } from '../../helpers/viewPortHelper'

import MegaMenuSlideTransition from '../MegaMenuSlideTransition/MegaMenuSlideTransition'
import styles from './MegaMenuCategories.scss'

const MegaMenuCategories = ({
  activeCategory,
  animateIn,
  animateOut,
  isActive,
  isFallbackMenu,
  isLastLevel,
  isSlidingLeft,
  menuLevel,
  onBackButtonClick,
  onClick,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  onSetRef,
  onTouchStart,
  showDrillDownMenu,
  taxonomy,
}) => {
  // If taxonomy not passed in, then show list of subcategories of the active category
  const subCategories =
    taxonomy && taxonomy.length > 0
      ? taxonomy
      : (activeCategory && activeCategory.links && [].concat(...activeCategory.links)) || null
  const isDrillDownSubMenu = showDrillDownMenu && !isLastLevel
  const backButtonText = menuLevel === 1 ? 'Back to Categories' : activeCategory.title
  const unmountOnExit = (menuLevel === 1 && isMobile(global)) || showDrillDownMenu
  const isSeasonalCategory = (category) =>
    menuLevel === 1 && category && category.columns && category.columns.length === 0
  const getHref = (category) => {
    if (isDrillDownSubMenu) {
      return '#'
    }
    const clickOriginParam =
      menuLevel === 1 ? megaMenuLevelOneUrlBuilder(category.link) : megaMenuUrlBuilder(category.title)

    return `${category.link}${clickOriginParam}`
  }
  return (
    <li
      className={'mega-menu-column'}
      data-active={isActive}
      data-menulevel={menuLevel}
      data-fallback-menu={isFallbackMenu}>
      <MegaMenuSlideTransition
        enter={animateIn}
        exit={animateOut}
        isSlidingLeft={isSlidingLeft}
        show={isActive}
        unmountOnExit={unmountOnExit}>
        <ul className={styles.menu} data-el={`level-${menuLevel}`} data-submenu-active={activeCategory.index}>
          {/* Mobile Back Button */}
          <li className={`${styles.category} ${styles.menuItem} ${styles.back}`}>
            <a
              aria-haspopup={false}
              href={'/'}
              tabIndex={0}
              className={styles.link}
              data-megamenu-link
              data-test={'megamenu-back-to-categories'}
              onClick={(evt) => onBackButtonClick(menuLevel - 1, evt)}>
              <ChevronIcon dir='left' className={styles.leftArrow} />
              {backButtonText}
            </a>
          </li>
          <ul className={styles.scrollableArea}>
            {isDrillDownSubMenu && activeCategory.link && (
              <li className={`${styles.category} ${styles.menuItem}`}>
                <a
                  aria-haspopup={false}
                  href={`${activeCategory.link}${megaMenuLevelOneUrlBuilder(activeCategory.link)}`}
                  tabIndex={0}
                  className={styles.link}
                  data-test={`megamenu-shop-all-${activeCategory.title.toLowerCase()}-category`}>
                  Shop All {activeCategory.title}
                </a>
              </li>
            )}
            {subCategories &&
              subCategories.map((category, idx) => (
                <MegaMenuCategory
                  category={category}
                  hasChevron={isDrillDownSubMenu || isDesktop(global)}
                  hasHoverExclusionZone={!(taxonomy.length === idx + 1)}
                  href={getHref(category)}
                  idx={idx}
                  isDesktop={isDesktop}
                  isExpanded={activeCategory.index === idx}
                  isSeasonal={isSeasonalCategory(category)}
                  key={`${category.title}-${idx}`}
                  menuLevel={menuLevel}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onSetRef={onSetRef}
                  onTouchStart={onTouchStart}
                />
              ))}
          </ul>
        </ul>
      </MegaMenuSlideTransition>
    </li>
  )
}

MegaMenuCategories.propTypes = {
  activeCategory: PropTypes.shape({
    index: PropTypes.number,
    title: PropTypes.string,
    link: PropTypes.string,
    links: PropTypes.array,
  }),
  animateIn: PropTypes.bool,
  animateOut: PropTypes.bool,
  isActive: PropTypes.bool,
  isFallbackMenu: PropTypes.bool,
  isLastLevel: PropTypes.bool,
  isSlidingLeft: PropTypes.bool,
  menuLevel: PropTypes.number,
  onBackButtonClick: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onSetRef: PropTypes.func,
  onTouchStart: PropTypes.func,
  showDrillDownMenu: PropTypes.bool,
  taxonomy: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            links: PropTypes.arrayOf(
              PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
              }),
            ),
          }),
        ),
      ),
    }),
  ),
}

MegaMenuCategories.defaultProps = {
  activeCategory: {
    index: null,
    title: null,
    link: null,
    links: null,
  },
  animateIn: true,
  animateOut: true,
  isActive: false,
  isFallbackMenu: false,
  isLastLevel: false,
  isSlidingLeft: false,
  menuLevel: null,
  onBackButtonClick: () => {},
  onClick: () => {},
  onKeyDown: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onSetRef: () => {},
  onTouchStart: () => {},
  showDrillDownMenu: false,
  taxonomy: [],
}

export default MegaMenuCategories
