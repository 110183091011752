import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'
import debounce from '@argos/utils/esnext/debounce'
import canUseDom from '@argos/utils/esnext/canUseDom'
import { ChevronIcon } from '@boltui/icons'
import MegaMenuSlideTransition from '../../components/MegaMenuSlideTransition/MegaMenuSlideTransition'

import { MegaMenu } from '../../containers'
import { NavLink, MegaButtons, MobileSectionTitle } from '../../components'
import { MegaNavService, MegaButtonsService } from '../../services'
import { NavigationStore } from '../../stores'
import { keyCodes, onHandleMenuFocus, onHandleMenuCategoryFocus } from '../../helpers/menuKeyboardControls'
import getSiteSection from '../../helpers/getSiteSection'
import { isMobile } from '../../helpers/viewPortHelper'

import styles from './Navigation.scss'

export class Navigation extends Component {
  static propTypes = {
    activeCategory: PropTypes.shape({
      index: PropTypes.number,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
    activeMenu: PropTypes.number,
    focusedItem: PropTypes.number,
    keyboardActivated: PropTypes.bool,
    menuToggled: PropTypes.bool.isRequired,
    onSetKeyboardActivated: PropTypes.func,
    onToggleMenu: PropTypes.func.isRequired,
    onUpdateActiveCategory: PropTypes.func,
    onUpdateActiveMenu: PropTypes.func,
    onUpdateFocusedItem: PropTypes.func,
  }

  static defaultProps = {
    activeCategory: {
      index: null,
      title: null,
      link: null,
    },
    megaButtons: [],
    activeMenu: 0,
    focusedItem: null,
    keyboardActivated: false,
    onUpdateActiveCategory: () => {},
    onUpdateActiveMenu: () => {},
    onUpdateFocusedItem: () => {},
    onSetKeyboardActivated: () => {},
  }

  constructor(props) {
    super(props)

    this.navRef = React.createRef()
    this.shopAllRef = React.createRef()
    this.itemRefs = []
    this.flyoutItemRefs = []
    this.menuInTimeout = 100
    this.menuExitTimeout = 50
    this.shopHoverTimer = null
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleDocumentHover = debounce(this.handleDocumentHover.bind(this), this.menuExitTimeout)
    this.handleMenuKeydown = this.handleMenuKeydown.bind(this)
  }

  get categoryIsExpanded() {
    return typeof this.props.activeCategory.index === 'number'
  }

  async componentDidMount() {
    if (!this.props.taxonomyLoaded) {
      try {
        // Call taxonomy on client if not SSR OR if call failed on the server
        if (!(this.props.taxonomy && this.props.taxonomy.length)) {
          const taxonomy = await MegaNavService()
          this.props.setState({ taxonomy })
        }

        if (!this.props.megaButtons.length) {
          const megaButtons = await MegaButtonsService()
          this.props.setState({ megaButtons })
        }
        this.props.setState({ taxonomyLoaded: true })
      } catch (e) {
        throw new Error(e)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.menuToggled && nextProps.menuToggled) {
      this.setupListeners()
    }

    if (this.props.menuToggled && !nextProps.menuToggled) {
      this.destroyMenu()
    }
  }

  setupListeners() {
    document.addEventListener('click', this.handleDocumentClick)
    document.addEventListener('mousemove', this.handleDocumentHover)
    document.addEventListener('keydown', this.handleMenuKeydown)
  }

  removeListeners() {
    document.removeEventListener('click', this.handleDocumentClick)
    document.removeEventListener('mousemove', this.handleDocumentHover)
    document.removeEventListener('keydown', this.handleMenuKeydown)
  }

  destroyMenu = (evt) => {
    const {
      onToggleMenu,
      onSetKeyboardActivated,
      onUpdateActiveCategory,
      onUpdateActiveDrillDownCategory,
      onUpdateActiveDrillDownLevelTwoCategory,
      onUpdateFlyoutMenu,
    } = this.props
    onUpdateFlyoutMenu(false)
    onToggleMenu(evt, false, this.props.onUpdateActiveMenu)
    onSetKeyboardActivated(false)
    onUpdateActiveCategory({ index: null, title: null, link: null })
    onUpdateActiveDrillDownCategory({ title: null, link: null, links: null })
    onUpdateActiveDrillDownLevelTwoCategory({ title: null, links: null })
    this.handleNewFocusedItem(null)
    this.removeListeners()
    // return this.navRef.current.querySelector('#ShopLink').focus()
  }

  destroyCategory = () => {
    this.handleNewCategoryFocus(false)
    this.props.onUpdateActiveCategory({ index: null, title: null, link: null })
    return this.handleNewFocusedItem(this.props.focusedItem)
  }

  handleNewFocusedItem = (idx) => {
    if (typeof idx !== 'object') {
      this.itemRefs[idx].focus()
    }

    this.props.onUpdateFocusedItem(idx)
  }

  handleNewFlyoutFocusedItem = (idx) => {
    if (typeof idx !== 'object') {
      this.flyoutItemRefs[idx] && this.flyoutItemRefs[idx].focus()
    }
    this.props.onUpdateFlyoutFocusedItem(idx)
  }

  handleNewCategoryFocus = (toggle) => {
    this.categoryIsFocused = toggle
  }

  handleSavedCategoryLinks = () => {
    this.categoryImageLink = this.navRef.current.querySelector('[data-category-active="true"] * [data-megamenu-image]')
    this.categoryTextLinks = this.navRef.current.querySelectorAll(
      '[data-category-active="true"] * [data-megamenu-link]',
    )
  }

  handleMenuKeydown(evt) {
    if (
      this.categoryIsExpanded &&
      evt &&
      (evt.keyCode === keyCodes.TAB || evt.keyCode === keyCodes.ESC || evt.keyCode === keyCodes.LEFT)
    ) {
      return onHandleMenuCategoryFocus(evt, {
        shopAllRef: this.shopAllRef,
        categoryIsFocused: this.categoryIsFocused,
        categoryImageLink: this.categoryImageLink,
        categoryTextLinks: this.categoryTextLinks,
        onUpdateFocusedItem: this.handleNewFocusedItem,
        onUpdateActiveCategory: this.props.onUpdateActiveCategory,
        onToggleCategoryFocus: this.handleNewCategoryFocus,
        onSaveCategoryLinks: this.handleSavedCategoryLinks,
        onDestroyCategory: this.destroyCategory,
      })
    }

    return this.props.flyoutActive
      ? onHandleMenuFocus(evt, {
          focusedItem: this.props.flyoutFocusedItem,
          menuLength: this.props.megaButtons[0].links.length,
          startElementTag: 'A',
          endElementTag: 'A',
          onUpdateFocusedItem: this.handleNewFlyoutFocusedItem,
          onDestroy: this.destroyMenu,
          categoryIsExpanded: false,
        })
      : onHandleMenuFocus(evt, {
          focusedItem: this.props.focusedItem,
          menuLength: this.props.taxonomy.length,
          startElementTag: 'A',
          endElementTag: 'A',
          onUpdateFocusedItem: this.handleNewFocusedItem,
          onDestroy: this.destroyMenu,
          categoryIsExpanded: this.categoryIsExpanded,
        })
  }

  handleDocumentClick(evt) {
    if (!this.navRef.current.contains(evt.target) && this.props.menuToggled) {
      this.destroyMenu()
    }
  }

  handleDocumentHover(evt) {
    if (
      !isMobile(global) &&
      !this.navRef.current.contains(evt.target) &&
      !this.props.keyboardActivated &&
      this.props.menuToggled
    ) {
      this.destroyMenu(evt)
    }
  }

  // Reset state related to shop menu
  resetShopMenuState = () => {
    // Clear activeCategory set in shop menu
    if (this.categoryIsExpanded) {
      this.destroyCategory()
    }

    // Reset last menu level
    if (this.props.previousMenu !== 0) {
      this.props.onUpdatePreviousMenu(0)
    }
  }

  // Reset flyout state if active
  resetFlyout = () => {
    if (this.props.flyoutActive) {
      this.props.onUpdateFlyoutMenu(false)
    }
  }

  handleMenuIn = (evt) => {
    this.resetFlyout()
    if (!this.props.menuToggled) {
      if (evt.type === 'focus') {
        if (evt.keyCode === keyCodes.ENTER) {
          this.props.onSetKeyboardActivated(true)
          this.props.onToggleMenu(evt, true, this.props.onUpdateActiveMenu)
          this.setupListeners()
        }
        return false
      }

      this.shopHoverTimer = setTimeout(() => {
        this.resetFlyout()
        this.props.onToggleMenu(evt, true, this.props.onUpdateActiveMenu)
        this.setupListeners()
      }, this.menuInTimeout)
    }
    return false
  }

  handleMenuOut = () => {
    clearTimeout(this.shopHoverTimer)
  }

  handleShopKeyDown = (evt) => {
    if (evt.which === keyCodes.SPACE) {
      this.handleShopClick(evt)
    }
  }

  handleShopClick = (evt) => {
    evt.preventDefault()

    this.resetFlyout()

    if (!this.props.menuToggled || this.props.flyoutActive) {
      this.props.onSetKeyboardActivated(true)
      this.props.onToggleMenu(evt, true, this.props.onUpdateActiveMenu)
      this.setupListeners()
    } else {
      this.props.onSetKeyboardActivated(false)
      this.props.onToggleMenu(evt, false, this.props.onUpdateActiveMenu)
      this.removeListeners()
    }
  }

  handleNewMenu = (evt, level) => {
    evt.preventDefault()
    this.props.onUpdateActiveMenu(level)
  }

  handleNewCategory = (category) => {
    this.props.onUpdateFocusedItem(category.index)
    this.props.onUpdateActiveCategory(category)
  }

  handleRoute(e, type, href) {
    const { routes } = this.props

    if (routes[type] && typeof routes[type] === 'function') {
      e.preventDefault()
      this.destroyMenu(e)
      return routes[type].call(this, href)
    }

    return false
  }

  handleFlyoutKeyDown = (evt) => {
    if (evt.which === keyCodes.SPACE) {
      this.handleFlyoutClick(evt)
    }
  }

  handleFlyoutClick = (evt) => {
    evt.preventDefault()

    this.resetShopMenuState()

    if (!this.props.flyoutActive) {
      this.props.onUpdateFlyoutMenu(true)

      // For tablet/desktop
      if (!isMobile(global)) {
        if (!this.props.menuToggled) {
          this.props.onToggleMenu(evt, true, this.props.onUpdateActiveMenu)
        }
        this.props.onSetKeyboardActivated(true)
      }
    } else {
      this.props.onUpdateFlyoutMenu(false)

      // For tablet/desktop
      if (!isMobile(global)) {
        // If closing the flyout menu via click
        this.props.onToggleMenu(evt, false, this.props.onUpdateActiveMenu)
      }
    }
  }

  // Handle when mouse over flyout button
  handleFlyoutIn = (evt) => {
    evt.preventDefault()
    // If on tablet + desktop
    if (!isMobile(global)) {
      this.resetShopMenuState()

      // If flyout is not active
      if (!this.props.flyoutActive) {
        // Need to set toggle menu to true because of componentWillReceiveProps in MegaMenu container...
        if (!this.props.menuToggled) {
          this.props.onToggleMenu(evt, true, this.props.onUpdateActiveMenu)
        }
        this.props.onUpdateFlyoutMenu(true)
      }
    }
  }

  getMegaButtons = (megaButtons, routes, isMobileNav) => {
    const { menuToggled, flyoutActive } = this.props
    return (
      <MegaButtons
        className={styles.menuItem}
        closeNav={(evt) => this.destroyMenu(evt)}
        displayId={isMobileNav ? 'megabutton-mobile' : 'megabutton'}
        flyoutActive={menuToggled && flyoutActive}
        links={megaButtons}
        onClick={(evt) => {
          evt.preventDefault()
          this.handleNewMenu(evt, 1) // set level 1
          this.handleFlyoutClick(evt)
        }}
        onKeyDown={this.handleFlyoutKeyDown}
        onMouseEnter={canUseDom() && navigator.maxTouchPoints <= 1 ? this.handleFlyoutIn : undefined}
        routes={routes}
      />
    )
  }

  render() {
    const { megaButtons, activeMenu, menuToggled, routes, previousMenu, flyoutActive } = this.props

    const setRef = (ref, idx, isFlyoutMenu) => {
      if (isFlyoutMenu) {
        this.flyoutItemRefs[idx] = ref
      } else {
        this.itemRefs[idx] = ref
      }
    }

    const isSlidingLeft = previousMenu <= activeMenu

    const getNavLink = (link, text) => `${link}?clickOrigin=header:${getSiteSection()}${text}`

    const storesHref = getNavLink('/stores/', 'stores')
    const helpHref = getNavLink('/help/', 'help')
    const adviceAndInspirationHref = getNavLink('/features/advice-and-inspiration', 'advice+and+inspiration')
    return (
      <Fragment>
        <nav
          className={styles.navigation}
          aria-expanded={menuToggled || flyoutActive}
          aria-label='Categories'
          data-active-menu={activeMenu}
          ref={this.navRef}>
          {/* MegaNav Component */}
          <div className={styles.links}>
            <ul className={styles.bar}>
              <NavLink
                id={'ShopLink'}
                aria={{
                  hasPopup: true,
                  expanded: menuToggled && !flyoutActive,
                }}
                text={'Shop'}
                href={'#megaMenu'}
                icon={<ChevronIcon dir='down' className={styles.downArrowIcon} />}
                onClick={this.handleShopClick}
                onFocus={canUseDom() && navigator.maxTouchPoints <= 1 ? this.handleMenuIn : undefined}
                onKeyDown={this.handleShopKeyDown}
                onMouseEnter={canUseDom() && navigator.maxTouchPoints <= 1 ? this.handleMenuIn : undefined}
                onMouseLeave={canUseDom() && navigator.maxTouchPoints <= 1 ? this.handleMenuOut : undefined}
              />
              {this.getMegaButtons(megaButtons, routes, false)}
            </ul>
            <MegaMenuSlideTransition
              isSlidingLeft={isSlidingLeft}
              show={activeMenu === 0}
              enter={previousMenu !== 0} // Don't slide in when toggling menu in
            >
              <ul className={styles.menu}>
                <MobileSectionTitle id='NavMobileTitle' text='Shop at Argos' />

                <NavLink
                  id={'CategoriesLink'}
                  aria={{
                    hasPopup: true,
                    expanded: menuToggled,
                  }}
                  text={'Shop all categories'}
                  href={'#'}
                  icon={<ChevronIcon dir='right' className={styles.rightArrowIcon} />}
                  onClick={(evt) => this.handleNewMenu(evt, 1)}
                />

                {this.getMegaButtons(megaButtons, routes, true)}

                <MobileSectionTitle id='NavMobileTitle' text='Need Help?' />

                <NavLink
                  id={'Stores'}
                  text={'Stores'}
                  href={storesHref}
                  onClick={(e) => this.handleRoute(e, 'stores', storesHref)}
                />

                <NavLink
                  id={'Help'}
                  text={'Help'}
                  href={helpHref}
                  onClick={(e) => this.handleRoute(e, 'help', helpHref)}
                />

                <NavLink
                  id={'Advice & Inspiration'}
                  text={'Advice & Inspiration'}
                  href={adviceAndInspirationHref}
                  onClick={(e) => this.handleRoute(e, 'advice-and-inspiration', adviceAndInspirationHref)}
                />
              </ul>
            </MegaMenuSlideTransition>
            <MegaMenu
              megaButtons={megaButtons}
              shopAllRef={this.shopAllRef}
              onSetRef={(ref, idx, isFlyoutMenu) => setRef(ref, idx, isFlyoutMenu)}
              onUpdateActiveMenu={this.handleNewMenu}
              onUpdateActiveCategory={this.handleNewCategory}
              onToggleCategoryFocus={this.handleNewCategoryFocus}
            />
          </div>
        </nav>
      </Fragment>
    )
  }
}

const SubscribedComponent = subscribe([NavigationStore], (navigation) => ({
  menuToggled: navigation.menuToggled,
  flyoutActive: navigation.flyoutActive,
  flyoutFocusedItem: navigation.flyoutFocusedItem,
  focusedItem: navigation.focusedItem,
  keyboardActivated: navigation.keyboardActivated,
  activeMenu: navigation.activeMenu,
  previousMenu: navigation.previousMenu,
  activeCategory: navigation.activeCategory,
  onUpdateActiveMenu: (level) => navigation.onUpdateActiveMenu(level),
  onUpdatePreviousMenu: (level) => navigation.onUpdatePreviousMenu(level),
  onUpdateActiveCategory: (category) => navigation.onUpdateActiveCategory(category),
  onUpdateFlyoutMenu: (flyOutActive) => navigation.onUpdateFlyoutMenu(flyOutActive),
  onUpdateActiveDrillDownCategory: (category) => navigation.onUpdateActiveDrillDownCategory(category),
  onUpdateActiveDrillDownLevelTwoCategory: (category) => navigation.onUpdateActiveDrillDownLevelTwoCategory(category),
  onUpdateFocusedItem: (idx) => navigation.onUpdateFocusedItem(idx),
  onUpdateFlyoutFocusedItem: (idx) => navigation.onUpdateFlyoutFocusedItem(idx),
  onToggleMenu: (evt, toggled, onUpdateActiveMenu) => navigation.onToggleMenu(evt, toggled, onUpdateActiveMenu),
  onSetKeyboardActivated: (activated) => navigation.onSetKeyboardActivated(activated),
}))(subscribe()(Navigation))

const ProvidedComponent = () => (
  <ErrorBoundary>
    <SubscribedComponent />
  </ErrorBoundary>
)

export default ProvidedComponent
