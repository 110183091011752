import request from 'superagent'

import endpoints from '../config/endpoints'

export default (searchTerm) =>
  new Promise((resolve, reject) =>
    request
      .get(`${endpoints.suggestions}?term=${searchTerm}&fuzziness=true&size=5&includeFaq=true`)
      .then((result) => {
        if (!result.body) {
          return reject(new Error(`Invalid response: ${result.body}`))
        }

        return resolve({
          keywords: result.body.autoSuggest.keywords,
          faq: result.body.autoSuggest.faq,
        })
      })
      .catch((error) => reject(new Error(error))),
  )
