import React, { Component } from 'react'
import { subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'
import { InfoIcon, StoreIcon } from '@boltui/icons'

import canUseDom from '@argos/utils/esnext/canUseDom'
import { UserStore } from '../../stores'
import endpoints from '../../config/endpoints'
import config from '../../config/config'
import getSiteSection from '../../helpers/getSiteSection'
import { shouldSignOutToHomepage } from '../../helpers/signOutDestinationHelper'

import styles from './TopBar.scss'

export class TopBar extends Component {
  get signOutUrl() {
    const { storeId, langId, catalogId } = this.props
    return `${
      endpoints.signOut
    }?storeId=${storeId}&langId=${langId}&catalogId=${catalogId}&clickOrigin=header:${getSiteSection()}sign+out`
  }

  handleRoute(e, type, analyticsPath) {
    const { routes } = this.props
    if (routes[type] && typeof routes[type] === 'function') {
      if (canUseDom() && global.document.location.pathname === `/${type}/`) {
        return false
      }
      e.preventDefault()
      return routes[type].call(this, `${analyticsPath}${type}`)
    }

    return false
  }

  handleSignOut = (e) => {
    const { storeId, langId, catalogId, onSignOut } = this.props
    e.preventDefault()
    let goToHomepage = false
    if (canUseDom()) {
      const pathname = global.document.location.pathname
      goToHomepage = shouldSignOutToHomepage(pathname)
    }

    onSignOut({ storeId, langId, catalogId }, goToHomepage)
  }

  render() {
    const { firstName, userState } = this.props
    const analyticsPath = `header:${getSiteSection()}`
    return (
      <div className={styles.topBar}>
        <div className={styles.container}>
          {firstName && userState !== config.userState.guest && (
            <span className={styles.userLink}>
              {userState === config.userState.loggedIn && <span className={styles.userLink}>Hi, </span>}
              {userState === config.userState.recognised && <span className={styles.userLink}>Welcome back, </span>}
              <span className={styles.firstName}>{this.props.firstName}</span>(
              <a className={styles.signOut} href={this.signOutUrl} onClick={this.handleSignOut}>
                Sign out
              </a>
              )
            </span>
          )}

          <a
            className={styles.link}
            href={`/stores/?clickOrigin=${analyticsPath}stores`}
            onClick={(e) => this.handleRoute(e, 'stores', analyticsPath)}
            data-test='stores'>
            <StoreIcon height='20px' width='20px' className={styles.icon} />
            <span className={styles.label}>Stores</span>
          </a>
          <a
            className={styles.link}
            href={`/help/?clickOrigin=${analyticsPath}help`}
            onClick={(e) => this.handleRoute(e, 'help', analyticsPath)}
            data-test='help'>
            <InfoIcon height='20px' width='20px' className={styles.icon} />
            <span className={styles.label}>Help</span>
          </a>
        </div>
      </div>
    )
  }
}

const SubscribedComponent = subscribe([UserStore], (user) => ({
  firstName: user.firstName,
  userState: user.userState,
  onSignOut: (queryParams, goToHomepage) => user.signOut(queryParams, goToHomepage),
}))(subscribe()(TopBar))

const ProvidedComponent = () => (
  <ErrorBoundary>
    <SubscribedComponent />
  </ErrorBoundary>
)

export default ProvidedComponent
